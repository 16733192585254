import React, { useState, useEffect } from "react";
import { tabletscreen } from "../../constants/screen";
import { useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

export const SideBar = ({ menu, setIndex }) => {
  const { state = menu[0].id } = useLocation();
  const [prev, setPrev] = useState(state);

  useEffect(() => {
    if (window.screen.width > tabletscreen) {
      let element1 = document.getElementById(prev);
      element1.classList.add("active");
    }
  });

  const handleJobDescriptionCard = (item, index) => {
    setIndex(item);
    if (item.id === index && window.screen.width) {
      var element = document.getElementById(prev);
      element.classList.remove("active");
      var element1 = document.getElementById(index);
      element1.classList.add("active");
    }
    setPrev(index);
  };

  if (window.screen.width <= tabletscreen) {
    return (
      <Dropdown className="positionMenu dropdown text-center">
        <Dropdown.Toggle id="dropdown-basic">See All Positions</Dropdown.Toggle>
        <Dropdown.Menu
          id="dropdownMenu"
          onClick={() => {
            console.log("clicked");
          }}
        >
          {menu.map((item) => (
            <Dropdown.Item
              id={item.id}
              key={item.id}
              onClick={() => handleJobDescriptionCard(item, item.id)}
            >
              {item.sub_section_h4}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  } else {
    return (
      <ul className="SideBar col-lg-3 col-md-4 col-12">
        {menu.map((item, index) => (
          <li
            id={item.id}
            key={item.id}
            onClick={() => handleJobDescriptionCard(item, item.id)}
            className="is-cp-h5-heading mb-3 py-3 px-4 "
          >
            {item.sub_section_h4}
          </li>
        ))}
      </ul>
    );
  }
};
