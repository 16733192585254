import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import { aboutUs } from "../../Pages/DesignationPage/config";
import useCustomHooks from "../../hooks";
import { Button } from "react-bootstrap";
import { Jumbotron_Description } from "../../Pages/DesignationPage/config";
import { Heading } from "../../Helpers/Heading/Heading";

let customId = 0;

export const DesignationSection = (props) => {
  const location = useLocation();
  const [data, setData] = useState({ users: {}, isFetching: false });
  const [benefits, setBenefits] = useState({ benefits: [], isFetching: false });

  const [users] = useCustomHooks();
  const index = users.users.findIndex(
    (user) => user.slug === location.pathname.split("/")[4]
  );
  if (index > -1) {
    customId = users.users[index]._id;
  }

  useEffect(() => {
    const fetchUsers = async () => {
      let id = location.state;
      await axios
        .get(`${process.env.REACT_APP_BASE_URL}/teams/${id || customId}`)
        .then((response) => {
          setData({ users: response.data, isFetching: false });
          props.parentCallback(response.data.Data);
        })
        .catch((e) => {
          setData({ users: data.users, isFetching: false });
        });

      await axios
        .get(`${process.env.REACT_APP_BASE_URL}/benifits`)
        .then((response) => {
          setBenefits({ benefits: response.data, isFetching: false });
        })
        .catch((e) => {
          setBenefits({ benefits: benefits.benefits, isFetching: false });
        });

      return [data, benefits];
    };
    fetchUsers();
  });

  //benefits, data, location.state, props, users.users inside the arguments of useEffect

  if (props.isMobile) {
    return (
      <>
        <div
          users-aos="zoom-in"
          className="pt-4 position-tabs d-flex headerSection mobile-margin"
        >
          <div className="container col-12 p-0 h-100 d-flex justify-content-center flex-column">
            <div className="jumbotron d-flex justify-content-between align-items-center ">
              <div>
                <h5 className="position m-0 pb-4">
                  {`${data.users.JobTitle || ""}`}
                </h5>
                <div className="jumbotron-mobile text-center">
                  <img
                    src="/images/undraw_programmer_re_owql 2.svg"
                    alt="Job openings at Codeparva Technologies "
                  />
                </div>
                <p className="description m-0 pb-3">{Jumbotron_Description}</p>
                <Button
                  className="apply-button"
                  onClick={props.handleJobApplyModal}
                  variant="primary"
                >
                  Apply Now
                </Button>
              </div>

              <div className="jumbotron-img">
                <img
                  src="/images/undraw_programmer_re_owql 2.svg"
                  alt="Job openings at Codeparva Technologies "
                />
              </div>
            </div>

            <Heading
              className="m-0 py-2 mt-4 pl-3 role-overview"
              RenderAs="h5"
              color="primary"
              specialData={false}
            >
              {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a>
                  <span>Role Overview</span>
                </a>
              }
            </Heading>

            <div className="ml-5 line"></div>
            <div className="d-flex pl-3">
              <div className="col-12 p-0 h-100 d-flex justify-content-center flex-column px-0 py-3 h-100">
                <div className="roles">
                  <p className="m-0 pt-4">{aboutUs}</p>
                </div>
                <div className="d-flex job-description">
                  <div className="col-sm-10 col-12 p-0 pt-4">
                    <div
                      className="mb-4 sectionJobDescription"
                      dangerouslySetInnerHTML={{ __html: data.users.Data }}
                    ></div>
                  </div>
                </div>

                <div className="pt-4">
                  <Button
                    className="apply-button"
                    onClick={props.handleJobApplyModal}
                    variant="primary"
                  >
                    Apply Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          users-aos="zoom-in"
          className="pt-5 position-tabs d-flex headerSection mobile-margin"
        >
          <div className="positions-margin container mt-5 pt-5 col-12 p-0 d-flex justify-content-center flex-column">
            <div className="jumbotron d-flex justify-content-between align-items-center ">
              <div className="pt-3 pl-4">
                <h1 className="position m-0">
                  {`${data.users.JobTitle || ""}`}
                </h1>
                <p className="description m-0 pb-3">{Jumbotron_Description}</p>
                <Button
                  className="apply-button"
                  onClick={props.handleJobApplyModal}
                  variant="primary"
                >
                  Apply Now
                </Button>
              </div>

              <div className="jumbotron-img">
                <img
                  src="/images/undraw_programmer_re_owql 2.svg"
                  alt="Job openings at Codeparva Technologies "
                />
              </div>
            </div>
            <Heading
              RenderAs="h5"
              color="primary"
              className="m-0 py-2 mt-4 pl-5 role-overview"
              specialData={false}
            >
              {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a>
                  <span>Role Overview</span>
                </a>
              }
            </Heading>
            <div className="ml-5 line"></div>

            <div className="d-flex pl-5">
              <div className="col-12 p-0 h-100 d-flex justify-content-center flex-column px-0 py-3 h-100">
                <div className="roles">
                  <p className="m-0 pt-4">{aboutUs}</p>
                </div>
                <div className="d-flex job-description">
                  <div className="col-sm-10 col-12 p-0 pt-4">
                    <div
                      className="mb-4 sectionJobDescription"
                      dangerouslySetInnerHTML={{ __html: data.users.Data }}
                    ></div>
                  </div>
                </div>

                <div className="pt-4">
                  <Button
                    className="apply-button"
                    onClick={props.handleJobApplyModal}
                    variant="primary"
                  >
                    Apply Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};
