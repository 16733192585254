import React from "react";
import { Heading } from "../../Helpers/Heading/Heading";
import { CTA } from "../../Helpers/Button/Button";
import { useHistory } from "react-router-dom";

export const CareerSection = ({ item }) => {
  const history = useHistory();

  const { section_h2, section_para, sub_section } = item;

  return (
    <div className="container career_sections">
      <div className="d-flex flex-column">
        <Heading
          className="py-3 mb-0"
          RenderAs="h2"
          dataText={{ __html: section_h2 }}
        ></Heading>
        {section_para && (
          <p className="is-cp-body-text-3 col-md-8 col-12 mb-0 py-3 px-0">
            {section_para}
          </p>
        )}
      </div>
      <div className="py-md-3 py-0 d-flex  flex-lg-row box-container flex-wrap">
        {sub_section.map((item) => {
          const {
            sub_section_img,
            sub_section_h4,
            sub_section_para,
            sub_section_h5,
            current_opening,
            id,
          } = item;

          return (
            <div
              className={`col-lg-4 col-md-6 col-sm-6 col-12 d-flex flex-column py-3 `}
              key={id}
            >
              <div className="box">
                <img
                  src={sub_section_img.section_img}
                  alt={sub_section_img.section_alt}
                />
                {sub_section_h4 ? (
                  <Heading RenderAs="h5" className="py-4 mb-0">
                    <div
                      dangerouslySetInnerHTML={{ __html: sub_section_h4 }}
                    ></div>
                  </Heading>
                ) : (
                  <Heading RenderAs="h5" className="py-3 align-self-baseline">
                    {sub_section_h5}
                  </Heading>
                )}
                {current_opening && (
                  <>
                    <CTA
                      className="is-cp-secondary-button"
                      icon=""
                      href="/jobs/bengaluru/open-positions"
                      disabled={current_opening.length ? false : true}
                      onClick={() => {
                        history.push({
                          pathname: `/jobs/bengaluru/open-positions`,
                          state: id,
                        });
                      }}
                    >
                      {current_opening && current_opening.length}&nbsp; Position{" "}
                    </CTA>
                  </>
                )}

                {sub_section_para && (
                  <p className="is-cp-body-text-1">{sub_section_para}</p>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
