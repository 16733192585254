import * as img from "../../constants/images";

export const patten = [
  0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1,
  0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0,
];
export const Mpatten = [
  0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1,
  0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1,
];
export const images = [
  img.AI,
  img.REACT_LOGO,
  img.NODE_LOGO,
  img.DJANGO,
  img.AWS,
  img.WEBPACK,
  img.FIGMA,
  img.ELASTIC_SEARCH,
  img.JAVASCRIPT,
  img.REACT_NATIVE,
  img.PYTHON,
  img.CELERY,
  img.MATERIAL_UI,
  img.ICO_MOON,
  img.POSTGRE_SQL,
  img.REDUX,
  img.WEB_SOCKETS,
  img.NETLIFY,
  img.REDIS,
  img.GATSBY,
];
