import React, { Component } from "react";
import * as img from "../../constants/images";
// import { fadeIn } from "react-animations";
// import Radium from "radium";

// const styles = {
//   fadeIn: {
//     animation: "x 3s",
//     animationName: Radium.keyframes(fadeIn, "fadeIn"),
//   },
// };

export default class Storage extends Component {
  readMore = () => {
    this.props.toggle();
  };

  render() {
    if (this.props.isMobile) {
      return (
        <div className="d-flex headerSection container align-items-center ">
          <div className=" h-100 d-flex justify-content-center flex-column">
            <div data-aos="" className="w-100 d-flex justify-content-center ">
              <img className="storageImage" src={img.STORAGE_IMAGE} alt="img" />
            </div>
            <div style={{ padding: "2rem 0rem" }}>
              <p className="is-cp-body-text-1 mb-0">
                Over the years, this self storage industry has grown into a 38
                billion dollar behemoth, with its own challenges in logistics.
                This is what we, at CodeParva, hope to provide solutions for. We
                build management systems for self storage businesses. These
                systems provide an interface to maintain records of tenants,
                control access, manage billing, integrate third party software
                for payment etc. For later phases, we intend to build an entire
                suite of products, complete with business insight tools,
                electronic lock integration, payment gateways, a comprehensive
                CRM. With these features, we hope to provide a one stop solution
                for businesses of various sizes that work in this industry. It
                may all sound deceptively simple. But there are several
                challenges waiting for talented individuals to solve them. The
                biggest among them is our widely diverse customer base. Their
                needs vary based on several factors that are based on the size,
                demographics, location etc. Accounting for them all, is a fairly
                challenging task. As a consequence of this diversity, setting up
                standard business practices, consolidating a variety of user
                flows are some of the immediate problems we are working to
                solve.
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex headerSection container align-items-center leftSection">
          <div
            data-aos="fade-up-right"
            className="col-6 d-flex justify-content-start px-0 py-3 h-100"
          >
            <img className="storageImage" src={img.STORAGE_IMAGE} alt="img" />
          </div>
          <div className="col-6 pl-5 h-100 d-flex justify-content-center flex-column">
            <div style={{ padding: "3rem 0rem" }}>
              <p className="is-cp-body-text-1 mb-0">
                Over the years, this self storage industry has grown into a 38
                billion dollar behemoth, with its own challenges in logistics.
                This is what we, at CodeParva, hope to provide solutions for. We
                build management systems for self storage businesses. These
                systems provide an interface to maintain records of tenants,
                control access, manage billing, integrate third party software
                for payment etc. For later phases, we intend to build an entire
                suite of products, complete with business insight tools,
                electronic lock integration, payment gateways, a comprehensive
                CRM. With these features, we hope to provide a one stop solution
                for businesses of various sizes that work in this industry. It
                may all sound deceptively simple. But there are several
                challenges waiting for talented individuals to solve them. The
                biggest among them is our widely diverse customer base. Their
                needs vary based on several factors that are based on the size,
                demographics, location etc. Accounting for them all, is a fairly
                challenging task. As a consequence of this diversity, setting up
                standard business practices, consolidating a variety of user
                flows are some of the immediate problems we are working to
                solve.
              </p>
            </div>
          </div>
        </div>
      );
    }
  }
}
