import React, { Component } from "react";
import { PrivacyPolicy } from "../../Components/privacyPolicy/header";
import { Layout } from "../../Components/layout/Layout";

export class PrivacyPolicyPage extends Component {
  state = {
    isMobile: window.screen.width <= 768,
    isModalOpen: false,
  };

  updateDimensions() {
    this.setState({
      isMobile: window.screen.width <= 768,
    });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  render() {
    return (
      <Layout>
        <PrivacyPolicy isMobile={this.state.isMobile} />
      </Layout>
    );
  }
}
