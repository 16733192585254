import React from "react";

export const Heading = ({ children, color, RenderAs, className, dataText }) => {
  return (
    <RenderAs
      className={`is-cp-${RenderAs}-heading is-cp-${color}-color heading  ${className}`}
      dangerouslySetInnerHTML={dataText}
    >
      {children}
    </RenderAs>
  );
};
