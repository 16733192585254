import React, { useState } from "react";
import { Card } from "../../Helpers/Card/Card";
// import useCustomHooks from "../../hooks";
import { Heading } from "../../Helpers/Heading/Heading";
import { SideBar } from "../../Helpers/Sidebar/SideBar";
import { tabletscreen } from "../../constants/screen";
import { main_section } from "../../Pages/CareerPage/config";
import { useLocation } from "react-router-dom";

export const OpenPositionSection = (props) => {
  const { sub_section } = main_section[0];
  const { state = sub_section.length > 0 ? sub_section[0].id : null } = useLocation();
  const { innerHeight: height } = window;
  const curSectionToShow = sub_section.filter((item) => item.id === state);
  const [index, setIndex] = useState(curSectionToShow[0]);

  if (props.isMobile) {
    return (
      <div className="container openPositionSection ">
        <div
          className={`d-flex  ${
            tabletscreen
              ? "flex-wrap justify-content-center"
              : "justify-content-between"
          }`}
        >
          <SideBar menu={sub_section} setIndex={setIndex} />
          <div>
            <Heading
              RenderAs="h2"
              specialData={false}
              className=" currentOpeningHeading  px-4"
            >
              Current Openings for <br />
              <span className="is-cp-primary-color">
                {index.sub_section_h4}
              </span>
            </Heading>
            <Card openCategory={index} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container openPositionSection ">
        <div className="">
          <SideBar menu={sub_section} setIndex={setIndex} />
          <div
            id="CardScrollBar"
            className="col-md-8 col-9 CardScrollBar"
            style={{ maxHeight: height }}
          >
            <Heading
              RenderAs="h2"
              specialData={false}
              className=" currentOpeningHeading "
            >
              Current Openings for <br />
              <span className="is-cp-primary-color">
                {index.sub_section_h4}
              </span>
            </Heading>
            <Card openCategory={index} />
          </div>
        </div>
      </div>
    );
  }
};
// }
