import React from "react";
import * as img from "../../constants/images";
import { Nav } from "react-bootstrap";
import {
  faHome,
  faBriefcase,
  faUsers,
  faAddressCard,
  faBlog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Footer = (props) => {
  const showOpenPositionSection = JSON.parse(process.env.REACT_APP_HIRING).status || false
  if (props.isMobile) {
    return (
      <div className="w-100 footerBox d-flex flex-wrap is-cp-light-color">
        <div className="col-12">
          <Nav.Link href="/" className="p-0">
            <img
              className="navIcon"
              src={img.FOOTER_LOGO}
              alt="router links"
              style={{ marginBottom: 20 }}
            />
          </Nav.Link>
          <p style={{ marginTop: 20 }}>
            CodeParva is India's first Software company to build management
            software for the Self Storage Industry needs.
          </p>
        </div>
        <div className="col-12 mt-4">
          <p style={{ fontWeight: "bold", fontSize: "1rem" }}>Links</p>
          <p>
            <a href=" " className="anchorDesign">
              <FontAwesomeIcon icon={faHome} className="mr-1" /> Home
            </a>{" "}
          </p>
          <p>
            <a href="/career" className="anchorDesign">
              <FontAwesomeIcon icon={faBriefcase} className="mr-2" /> Work with
              us
            </a>
          </p>
          <p>
            <a
              href="https://blogs.codeparva.com"
              target="_blank"
              rel="noopener noreferrer"
              className="anchorDesign"
            >
              <FontAwesomeIcon icon={faBlog} className="mr-2" /> Blogs
            </a>
          </p>
          {showOpenPositionSection && <p>
            <a href="/jobs/bengaluru/open-positions" className="anchorDesign">
              <FontAwesomeIcon icon={faUsers} className="mr-2" /> Open Positions
            </a>
          </p>}
          <p>
            {
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                // eslint-disable-next-line no-script-url
                href="javascript:void(0)"
                className="anchorDesign"
                onClick={props.handleModal}
              >
                <FontAwesomeIcon icon={faAddressCard} className="mr-2" />{" "}
                Contact Us
              </a>
            }
          </p>
        </div>
        <div className="col-12 mt-4">
          <p style={{ fontWeight: "bold", fontSize: "1rem" }}>Contact Us</p>

          <p className="m-0" style={{ lineHeight: 2 }}>
            <a
              className="anchorDesign"
              href="https://g.page/codeparva?share"
              target="_blank"
              rel="noopener noreferrer"
            >
              CodeParva Technologies Pvt. Ltd.
              <br />
              2nd Floor, #45 24th Main Road
              <br />
              next to Shobha Tulip Apartments,
              <br />
              Ramaiah Garden, JP Nagar 7th Phase, <br />
              J. P. Nagar, Bengaluru, <br />
              Karnataka 560078
            </a>
          </p>
          <div className="w-100 d-flex p-0 mt-4 ">
            <a
              href="https://www.linkedin.com/company/codeparva/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="pr-4"
                style={{ height: "21px" }}
                src={img.LINKEDIN}
                alt="this img is link to linkedin account of codeparva"
              />
            </a>
            <a
              href="https://www.instagram.com/codeparvatechnologies/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="pr-4"
                style={{ height: "21px" }}
                src={img.INSTAGRAM}
                alt="this img is link to instagram account of codeparva"
              />
            </a>
            <a
              href="https://www.facebook.com/codeparvatechnologies/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="pr-4"
                style={{ height: "21px" }}
                src={img.FACEBOOK}
                alt="this img is link to facebook account of codeparva"
              />
            </a>
            <a
              href="https://twitter.com/codeparva/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="pr-4"
                style={{ height: "21px" }}
                src={img.TWITTER}
                alt="this img is link to twitter account of codeparva"
              />
            </a>
            <a
              href="https://medium.com/codeparva/latest/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="pr-4"
                style={{ height: "21px" }}
                src={img.MEDIUM}
                alt="this img is link to medium account of codeparva"
              />
            </a>
          </div>
        </div>
        <div className="col-12 mt-4">
          <a
            href="/privacy-policy"
            rel="noopener"
            className="anchorDesign"
            style={{ lineHeight: 2 }}
          >
            Privacy Policy
          </a>
          <p style={{ lineHeight: 2 }}>
            © 2021 by CodeParva Technologies Pvt Ltd. All rights reserved.
          </p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="footerBox is-cp-light-color  ">
        <div className="w-100 container  d-flex">
          <div className="col-5 d-flex flex-column">
            <Nav.Link href="/" className="p-0">
              <img
                className="navIcon"
                src={img.FOOTER_LOGO}
                alt="router links"
                style={{ marginBottom: 20 }}
              />
            </Nav.Link>

            <p className="is-cp-body-text-1">
              CodeParva is India's first Software company to build
            </p>
            <p className="is-cp-body-text-1" style={{ marginTop: -8 }}>
              management software for the Self Storage Industry needs.
            </p>

            <div className="mt-auto ">
              <a
                href="/privacy-policy"
                rel="noopener"
                className="anchorDesign is-cp-body-text-1"
              >
                Privacy Policy
              </a>
              <p className="mb-0 is-cp-body-text-1" style={{ lineHeight: 2 }}>
                © 2021 by CodeParva Technologies Pvt Ltd. All rights reserved.
              </p>
            </div>
          </div>
          <div className="col-4 footerDiv footerMiddleDiv">
            <p className="is-cp-body-text-1">Links</p>
            <p>
              <a href=" " className="anchorDesign is-cp-body-text-1">
                <FontAwesomeIcon icon={faHome} className="mr-1" /> Home
              </a>
            </p>
            <p>
              <a href="/career" className="anchorDesign">
                <FontAwesomeIcon icon={faBriefcase} className="mr-2" />
                Careers
              </a>
            </p>
            <p>
              <a
                href="https://blogs.codeparva.com"
                target="_blank"
                rel="noopener noreferrer"
                className="anchorDesign"
              >
                <FontAwesomeIcon icon={faBlog} className="mr-2" />
                Blogs
              </a>
            </p>
            {showOpenPositionSection && <p>
              <a href="/jobs/bengaluru/open-positions" className="anchorDesign">
                <FontAwesomeIcon icon={faUsers} className="mr-2" />
                Open Positions
              </a>
            </p>}
            <p>
              {
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  // href="javascript:void(0)"
                  className="anchorDesign"
                  onClick={props.handleModal}
                >
                  <FontAwesomeIcon icon={faAddressCard} className="mr-2" />
                  Contact Us
                </a>
              }
            </p>
          </div>
          <div className="col-3 footerDiv">
            <p className="is-cp-body-text-1">
              <FontAwesomeIcon icon={faAddressCard} className="mr-2" />
              Contact Us
            </p>
            <p className="is-cp-body-text-1">
              <a
                className="anchorDesign "
                href="https://g.page/codeparva?share"
                target="_blank"
                rel="noopener noreferrer"
              >
                CodeParva Technologies Pvt. Ltd.
                <br />
                2nd Floor, #45 24th Main Road
                <br />
                next to Shobha Tulip Apartments,
                <br />
                Ramaiah Garden, JP Nagar 7th Phase, <br />
                J. P. Nagar, Bengaluru, <br />
                Karnataka 560078
              </a>
            </p>
            <div className="w-100 d-flex p-0 mt-4">
              <a
                href="https://www.linkedin.com/company/codeparva/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="m-2"
                  style={{ height: "21px" }}
                  src={img.LINKEDIN}
                  alt="this img is link to linkedin account of codeparva"
                />
              </a>
              <a
                href="https://www.instagram.com/codeparvatechnologies/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="m-2"
                  style={{ height: "21px" }}
                  src={img.INSTAGRAM}
                  alt="this img is link to instagram account of codeparva"
                />
              </a>
              <a
                href="https://www.facebook.com/codeparvatechnologies/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="m-2"
                  style={{ height: "21px" }}
                  src={img.FACEBOOK}
                  alt="this img is link to facebook account of codeparva"
                />
              </a>
              <a
                href="https://twitter.com/codeparva/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="m-2"
                  style={{ height: "21px" }}
                  src={img.TWITTER}
                  alt="this img is link to twitter account of codeparva"
                />
              </a>
              <a
                href="https://medium.com/codeparva/latest/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="m-2"
                  style={{ height: "21px" }}
                  src={img.MEDIUM}
                  alt="this img is link to medium account of codeparva"
                />
              </a>
            </div>
          </div>
        </div>
        {/* <div className="w-100 footerBox d-flex">
          <div className="col-12 " style={{ lineHeight: 2 }}>
           
          </div>
        </div> */}
      </div>
    );
  }
};
