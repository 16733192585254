import React from "react";
import { Heading } from "../../Helpers/Heading/Heading";
import { careerPara } from "../../Pages/CareerPage/config";
import { CTA } from "../../Helpers/Button/Button";
import { mobileScreen } from "../../constants/screen";

export const CareerHeading = ({ PageHeadingH1, headImg, showOpenPositionSection }) => {
  const width = window.screen.width;
  return (
    <div className="careerHeading is-background-gradient">
      <div className="container d-flex justify-content-between align-items-center">
        <div className="col-md-6">
          <Heading RenderAs="h1" className="mb-0 careerPageHeading ">
            {PageHeadingH1}
          </Heading>
          {showOpenPositionSection ? <p
            className="is-cp-body-text-3 mb-0 CareerHeadingContent"
            dangerouslySetInnerHTML={{ __html: careerPara }}
          >
            { }
          </p> : <> <div className="is-cp-body-text-custom JoinOurTeam">NO OPENINGS CURRENTLY</div> <p
            className="is-cp-body-text-3 mb-0 CareerHeadingContent">Keep a lookout for new job openings.</p></>
          }
          {showOpenPositionSection && <CTA
            className="is-cp-primary-button"
            icon={""}
            href="jobs/bengaluru/open-positions"
          >
            View Open Positions
          </CTA>}
        </div>
        {width > mobileScreen && (
          <div className="col-md-5">
            <img src={headImg} alt="career_image" width="100%" />
          </div>
        )}
      </div>
    </div>
  );
};
