import React from "react";
import { useLocation } from "react-router";
import { Helmet } from "react-helmet";

const CanonicalTag = () => {
  const location = useLocation();

  return (
    <div>
      <Helmet>
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_URL}${location.pathname}`}
        />
      </Helmet>
    </div>
  );
};

export default CanonicalTag;
