import React from "react";
import { Heading } from "../../Helpers/Heading/Heading";
import { CTA } from "../../Helpers/Button/Button";
import { RIGHT_DARK } from "../../constants/images";
import { mobileScreen } from "../../constants/screen";

export const JobDescriptionSection = ({
  jobdescription,
  jobDescriptionPage_Heading,
  jobDescriptionPage_Info,
  perks_and_benifis,
}) => {
  const handleApplyPosition = () => {
    document.getElementById("tab2").click();
  };

  const width = window.screen.width;

  const { cta, eligibility, description, job_description } = jobdescription;
  return (
    <div className="jobDescriptionSection">
      <div style={{ borderBottom: "1px solid #f0f0f0" }}></div>
      <div className="container sectionPadding">
      <div className="AboutUs">
          <Heading RenderAs="h5" className="py-1 mb-0">
            {jobDescriptionPage_Heading}
          </Heading>
          <p className="mb-0 is-cp-body-text-1">{jobDescriptionPage_Info}</p>
        </div>
        <div className="EligibilitySection">
          <Heading RenderAs="h5">{eligibility.heading}</Heading>
          <div className="jobDescSection">
            {eligibility.content.map((eligibility, index) => (
              <div className="d-flex py-2" key={index}>
                <img src={RIGHT_DARK} alt="right" />
                <div className="is-cp-body-text-1 mb-0 ">{eligibility}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="JobResponsibilitySection ">
          <Heading
            RenderAs="h4"
            className="JobResponsibilitySectionHeading mb-0"
          >
            {job_description.heading}
          </Heading>
          <div className="JobResponsibilitySectionContent">
            {job_description.content.map((job_desc, index) => (
              <div
                key={index}
                className={`JobResponsibilitySectionSkills ${
                  job_desc.heading === "SOFT SKILLS" ? "col-md-4" : "col-md-6"
                } ${width <= mobileScreen && index === 0 && "pb-4"} `}
              >
                {" "}
                <Heading RenderAs="h6" color="" className="SkillsHeading">
                  {job_desc.heading}
                </Heading>
                <ol className=" mb-0">
                  {job_desc.content.map((list, index) => (
                    <div className="d-flex " key={index}>
                      <img src={RIGHT_DARK} alt="right" />
                      <li
                        className="is-cp-body-text-1 "
                        dangerouslySetInnerHTML={{ __html: list }}
                      ></li>
                    </div>
                  ))}
                </ol>
              </div>
            ))}
          </div>
        </div>
        {description.map((JobDescriptions, index) => {
          const { heading, content } = JobDescriptions;
          return (
            <div className="WorkHereSection" key={index}>
              <Heading RenderAs="h4" className="pt-0 pb-3 mb-0">
                {heading}
              </Heading>

              <ol className="jobDescSection  mb-0 pt-3">
                {content.map((JobDescriptionList, index) => {
                  return (
                    <div key={index}>
                      <div className="d-flex ">
                        <img src={RIGHT_DARK} alt="right" />
                        <li className="is-cp-body-text-1 ">
                          {JobDescriptionList.list}
                        </li>
                      </div>
                      {JobDescriptionList.sublist && (
                        <ul>
                          {JobDescriptionList.sublist.map((list, index) => (
                            <li
                              key={index}
                              className="is-cp-body-text-1"
                              dangerouslySetInnerHTML={{ __html: list.list }}
                            ></li>
                          ))}
                        </ul>
                      )}
                    </div>
                  );
                })}
              </ol>
            </div>
          );
        })}
        <div className="PerksAndBenefits">
          <Heading RenderAs="h4" className="pt-0 mb-0 PerksAndBenefitsHeading">
            {perks_and_benifis.heading}
          </Heading>
          <div className="jobDescSection">
            {perks_and_benifis.content.map((content, index) => (
              <div className="d-flex py-2" key={index}>
                <img src={RIGHT_DARK} alt="right" />
                <p
                  className="mb-0 is-cp-body-text-1"
                  dangerouslySetInnerHTML={{ __html: content }}
                ></p>
              </div>
            ))}
          </div>
        </div>
        {/* <>
          <p className="is-cp-body-text-1 pt-1 pb-4 ">
            Also, some links and material to give you a head start on
            understanding a bit more about CodeParva !
          </p>
          <div className="w-100 d-flex p-0 mt-4">
            <a
              href="https://www.linkedin.com/company/codeparva/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="m-2"
                style={{ height: "21px" }}
                src={img.LINKEDIN_DARK}
                alt="this img is link to linkedin account of codeparva"
              />
            </a>
            <a
              href="https://www.instagram.com/codeparvatechnologies/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="m-2"
                style={{ height: "21px" }}
                src={img.INSTAGRAM_DARK}
                alt="this img is link to instagram account of codeparva"
              />
            </a>
            <a
              href="https://medium.com/codeparva/latest/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="m-2"
                style={{ height: "21px" }}
                src={img.MEDIUM_DARK}
                alt="this img is link to medium account of codeparva"
              />
            </a>
            <a
              href="https://www.facebook.com/codeparvatechnologies/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="m-2"
                style={{ height: "21px" }}
                src={img.FACEBOOK_DARK}
                alt="this img is link to facebook account of codeparva"
              />
            </a>
            <a
              href="https://twitter.com/codeparva/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="m-2"
                style={{ height: "21px" }}
                src={img.TWITTER_DARK}
                alt="this img is link to twitter account of codeparva"
              />
            </a>
          </div>
        </> */}
        <div className="apply_cta">
          <CTA
            className="is-cp-primary-small-button"
            icon=""
            onClick={() => handleApplyPosition()}
          >
            {cta[1].label}
          </CTA>
        </div>
      </div>
    </div>
  );
};
