import React, { Component } from "react";

//import components

import { DesignationSection } from "../../Components/designationSection/designationSection";
import JobApplyPopupModal from "../../Components/jobApplyPopupModal/modal";
import {
  SE_Title,
  SSE_Title,
  UIUX_Title,
  SE_Description,
  scriptSSE,
  scriptSE,
  scriptUIUX,
  aboutUs,
  ogGraphSE,
  ogGraphSSE,
  ogGrapUIUX,
  twitterCardSE,
  twitterCardSSE,
  twitterCardUIUX,
  SSE_Description,
  UIUX_Description,
} from "./config";
import { Layout } from "../../Components/layout/Layout";

export class DesignationPage extends Component {
  state = {
    isMobile: window.screen.width <= 768,
    isModalOpen: false,
    isJobApplyModalOpen: false,
    readMoreJobPosition: false,
    script: {},
    Title: {},
    Description: {},
    ogGraph: {},
    twitterCard: {},
  };

  updateDimensions() {
    this.setState({
      isMobile: window.screen.width <= 768,
    });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  handleReadMoreJobPosition = () => {
    setTimeout(() => {
      this.setState({ readMoreJobPosition: !this.state.readMoreJobPosition });
    }, 100);
  };

  handleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  handleJobApplyModal = () => {
    this.setState({ isJobApplyModalOpen: !this.state.isJobApplyModalOpen });
  };

  callback = (description) => {
    scriptSSE.description = JSON.stringify(aboutUs.concat(description));
    scriptSE.description = JSON.stringify(aboutUs.concat(description));
    scriptUIUX.description = JSON.stringify(aboutUs.concat(description));

    // ogGraphSSE.map(
    //   (ogGraphSSE) =>
    //     (ogGraphSSE["name"] = ogGraphSSE["property"].includes("description")
    //       ? JSON.stringify(aboutUs.concat(description))
    //       : "")
    // );

    // ogGraphSE.map(
    //   (ogGraphSE) =>
    //     (ogGraphSE["name"] = ogGraphSE["property"].includes("description")
    //       ? JSON.stringify(aboutUs.concat(description))
    //       : "")
    // );

    // ogGrapUIUX.map(
    //   (ogGrapUIUX) =>
    //     (ogGrapUIUX["name"] = ogGrapUIUX["property"].includes("description")
    //       ? JSON.stringify(aboutUs.concat(description))
    //       : "")
    // );

    // twitterCardSSE.map(
    //   (twitterCardSSE) =>
    //     (twitterCardSSE["property"] = twitterCardSSE["name"].includes(
    //       "description"
    //     )
    //       ? `$${JSON.stringify(aboutUs.concat(description))}`
    //       : "")
    // );

    // twitterCardSE.map(
    //   (twitterCardSE) =>
    //     (twitterCardSE["property"] = twitterCardSE["name"].includes(
    //       "description"
    //     )
    //       ? `$${JSON.stringify(aboutUs.concat(description))}`
    //       : "")
    // );

    // twitterCardUIUX.map(
    //   (twitterCardUIUX) =>
    //     (twitterCardUIUX["property"] = twitterCardUIUX["name"].includes(
    //       "description"
    //     )
    //       ? JSON.stringify(aboutUs.concat(description))
    //       : "")
    // );

    this.setState({
      script: `${this.props.location.JobTitle}`.includes("Senior")
        ? scriptSSE
        : `${this.props.location.JobTitle}`.includes("Software")
        ? scriptSE
        : scriptUIUX,

      Title: `${this.props.location.JobTitle}`.includes("Senior")
        ? SSE_Title
        : `${this.props.location.JobTitle}`.includes("Software")
        ? SE_Title
        : UIUX_Title,

      Description: `${this.props.location.JobTitle}`.includes("Senior")
        ? SSE_Description
        : `${this.props.location.JobTitle}`.includes("Software")
        ? SE_Description
        : UIUX_Description,

      ogGraph: `${this.props.location.JobTitle}`.includes("Senior")
        ? ogGraphSSE
        : `${this.props.location.JobTitle}`.includes("Software")
        ? ogGraphSE
        : ogGrapUIUX,

      twitterCard: `${this.props.location.JobTitle}`.includes("Senior")
        ? twitterCardSSE
        : `${this.props.location.JobTitle}`.includes("Software")
        ? twitterCardSE
        : twitterCardUIUX,
    });
  };

  render() {
    return (
      <Layout>
        <JobApplyPopupModal
          show={this.state.isJobApplyModalOpen}
          handleJobApplyModal={this.handleJobApplyModal}
          JobTitle={this.props.location.JobTitle}
        />
        <div className="p-0 d-flex flex-column mt-1">
          <DesignationSection
            parentCallback={this.callback}
            readMoreJobPosition={this.state.readMoreJobPosition}
            handleReadMoreJobPosition={this.handleReadMoreJobPosition}
            isMobile={this.state.isMobile}
            handleJobApplyModal={this.handleJobApplyModal}
          />
        </div>
      </Layout>
    );
  }
}
