export const description = `The first Indian software development company that specialises in delivering high-quality integrated solutions for the Global self storage industry.`;

export const Title = [
  {
    Title: "Open Positions | Codeparva Technologies",
  },
];

export const Description = [
  {
    property: "description",
    content:
      "Tread your fit & unveil your true potential. Scroll to explore our job openings, a workplace culture that encourages innovation and supports diversity and inclusion.",
  },
];

export const ogGraph = [
  {
    property: `og:description`,
    content: `${description}`,
  },
  {
    property: `og:type`,
    content: `website`,
  },
  {
    property: `og:title`,
    content: `${process.env.REACT_APP_WEBSITE_NAME}`,
  },
  {
    property: `og:site_name`,
    content: `${process.env.REACT_APP_WEBSITE_NAME}`,
  },
  {
    property: `og:url`,
    content: `${process.env.REACT_APP_URL}/open-positions`,
  },
  {
    property: `og:image`,
    content: `https://codeparva.com/images/main-logo.svg`,
  },
];

export const twitterCard = [
  {
    name: `twitter:site`,
    content: `@${process.env.REACT_APP_WEBSITE_NAME}`,
  },
  {
    name: `twitter:card`,
    content: `summary`,
  },
  {
    name: `twitter:creator`,
    content: `@codeparva`,
  },
  {
    name: `twitter:title`,
    content: `@${process.env.REACT_APP_WEBSITE_NAME}`,
  },
  {
    name: `twitter:description`,
    content: `${description}`,
  },
  {
    name: `twitter:image`,
    content: `https://codeparva.com/images/main-logo.svg`,
  },
];
