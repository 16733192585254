import React, { Component } from "react";
import { OpenPositionSection } from "../../Components/openPositionSection/ourPositionSection";
import { ogGraph, twitterCard, Title, Description } from "./config";
import { Layout } from "../../Components/layout/Layout";
import JobApplyPopupModal from "../../Components/jobApplyModal/jobApplyModal";
export class OpenPositionPage extends Component {
  state = {
    isMobile: window.screen.width <= 768,
    isModalOpen: false,
    isJobApplyModalOpen: false,
    readMoreJobPosition: false,
  };

  updateDimensions() {
    this.setState({
      isMobile: window.screen.width <= 768,
    });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  handleReadMoreJobPosition = () => {
    setTimeout(() => {
      this.setState({ readMoreJobPosition: !this.state.readMoreJobPosition });
    }, 100);
  };

  handleJobApplyModal = () => {
    this.setState({ isJobApplyModalOpen: !this.state.isJobApplyModalOpen });
  };

  render() {
    return (
      <Layout
        ogGraph={ogGraph}
        twitterCard={twitterCard}
        Title={Title}
        Description={Description}
      >
        <JobApplyPopupModal
          show={this.state.isJobApplyModalOpen}
          handleJobApplyModal={this.handleJobApplyModal}
        />
        <div className="p-0 d-flex flex-column mt-1 OpenPositionPage">
          <OpenPositionSection
            readMoreJobPosition={this.state.readMoreJobPosition}
            handleReadMoreJobPosition={this.handleReadMoreJobPosition}
            isMobile={this.state.isMobile}
            handleJobApplyModal={this.handleJobApplyModal}
          />
        </div>
      </Layout>
    );
  }
}
