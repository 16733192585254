import React, { Component } from "react";
// import { fadeIn } from "react-animations";
// import Radium from "radium";
import * as img from "../../constants/images";
import { Heading } from "../../Helpers/Heading/Heading";

// const styles = {
//   fadeIn: {
//     animation: "x 3s",
//     animationName: Radium.keyframes(fadeIn, "fadeIn"),
//   },
// };

export class OurProductSection extends Component {
  readMore = () => {
    this.props.toggle();
  };

  render() {
    if (this.props.isMobile) {
      return (
        <div className="d-flex headerSection container align-items-center ">
          <div className="col-12 p-0 h-100 d-flex justify-content-center flex-column">
            <div className="w-100 justify-content-center d-flex">
              <Heading RenderAs="h2">Our Product</Heading>
            </div>
            <div
              data-aos="fade-up"
              className="w-100 d-flex py-4  pt-5 justify-content-center text-center"
            >
              <img className="headerLogo" src={img.OUR_PRODUCT} alt="logo" />
            </div>
            <div style={{ padding: "1rem 0rem" }}>
              <p className="is-cp-body-text-1 mb-0">
                With the boom of capitalism, ease of production and logistics,
                the average person in the world owns more material possessions
                than ever. Sentimental or otherwise, we always have a great
                reason to hold on to these possessions. But with the limited
                amount of real estate we own, we often have to part with them.
                This is where the self storage industry comes in. They
                compensate for the real estate deficit to help us keep our
                precious possessions. It has helped families hold on to their
                belongings while they move, provided a buffer for companies that
                downsized their operations - all for a reasonable price. The
                demands for the services of this industry were so high, that
                it's said to be recession proof.
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex headerSection container align-items-center ">
          <div className="col-6 p-0 h-100 d-flex justify-content-center flex-column">
            <Heading RenderAs="h2">Our Product</Heading>
            <div style={{ padding: "4rem 0rem" }}>
              <p className="is-cp-body-text-1 mb-0">
                With the boom of capitalism, ease of production and logistics,
                the average person in the world owns more material possessions
                than ever. Sentimental or otherwise, we always have a great
                reason to hold on to these possessions. But with the limited
                amount of real estate we own, we often have to part with them.
                This is where the self storage industry comes in. They
                compensate for the real estate deficit to help us keep our
                precious possessions. It has helped families hold on to their
                belongings while they move, provided a buffer for companies that
                downsized their operations - all for a reasonable price. The
                demands for the services of this industry were so high, that
                it's said to be recession proof.
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="col-6 d-flex  justify-content-end px-0 py-3 h-100"
          >
            <img className="headerLogo" src={img.OUR_PRODUCT} alt="logo" />
          </div>
        </div>
      );
    }
  }
}
