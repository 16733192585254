import React, { Component } from "react";
import {
  Modal,
  FormControl,
  Container,
  Row,
  Col,
  Button,
  Form,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import {
  jobApplyUrl,
  methodType,
  redirect,
  senderMailID,
  receiverMailID,
  bcc,
} from "../../config";
import Spinners from "../../Spinner";
import "../../styles/Components/Modal/modal.scss";
import validator from "validator";

function validate(
  emailID,
  fullName,
  fileName,
  yearsOfExp,
  techStack,
  noticePeriod,
  intrestPos
) {
  return {
    emailID: emailID.length === 0,
    fullName: fullName.length === 0,
    fileName: fileName === null,
    yearsOfExp: yearsOfExp.length === 0,
    techStack: techStack.length === 0,
    noticePeriod: noticePeriod.length === 0,
    intrestPos: intrestPos.length === 0,
  };
}

class JobApplyPopupModal extends Component {
  state = {
    jobPosition: null,
    fileName: null,
    fileData: null,
    showModal: true,
    buttonState: true,
    isFormFilled: false,
    fullName: "",
    yearsOfExp: "",
    techStack: "",
    noticePeriod: "",
    altContact: "",
    source: "",
    intrestPos: "",
    emailID: "",
    emailIDError: "",
    showPrivacyPolicyModal: false,
    isSubmitted: false,
    touched: {
      fileName: false,
      emailID: false,
      fullName: false,
      yearsOfExp: false,
      techStack: false,
      noticePeriod: false,
      intrestPos: false,
    },
    errorState: "",
    successState: "",
    alertShow: true,
    fileExtension: "",
    closeModal: false,
    showPopupModal: false,
  };

  componentDidMount() {
    this.setState({
      jobPosition: window.location.href
        .split("/")
        .pop()
        .replaceAll("-", " ")
        .replace(/(^.|\s+.)/g, (m) => m.toUpperCase()),
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show) {
      this.setState({
        showPopupModal: this.props.show,
      });
    }
  }
  formControl = (el) => {
    this.myFormRef = el;
  };

  getFileName = (e) => {
    // eslint-disable-next-line no-useless-computed-key
    this.setState({ touched: { ["fileName"]: true } });
    if (e.target.value !== undefined) {
      this.setState({ fileName: e.target.files[0].name });
      this.setState({ fileData: e.target.files[0] });
    }
    let extension = e.target.value.split(".").pop();
    this.setState({
      fileExtension: extension,
    });
  };

  enableSubmitButton = (e) => {
    this.setState({ buttonState: !e.target.checked });
  };

  validateEmailId = (e) => {
    let email = e.target.value;
    if (!validator.isEmail(email)) {
      this.setState({
        emailIDError: "Please check if the entered Email ID is valid",
      });
    } else {
      this.setState({ emailIDError: "Success" });
      this.setState({ emailID: email });
    }
  };

  validateSource = (e) => {
    let source = e.target.value;
    this.setState({ source: source });
  };

  handlePrivacyPolicyState = () => {
    this.setState({
      showPrivacyPolicyModal: true,
    });
  };

  altContactValidation = (e) => {
    this.setState({
      altContact: e.target.value.replace(/[a-zA-Z-!@#$%*?^&()+_]/gi, ""),
    });
  };

  nameValidation = (e) => {
    this.setState({
      fullName: e.target.value.replace(/[0-9-!@#$%*?^&()+_]/gi, ""),
    });
  };

  checkDisable = () => {
    if (
      this.state.buttonState !== true &&
      this.state.fileName !== null &&
      this.state.fullnName !== "" &&
      this.state.emailID !== "" &&
      this.state.yearsOfExp !== "" &&
      this.state.techStack !== "" &&
      this.state.noticePeriod !== "" &&
      this.state.intrestPos !== ""
    ) {
      return false;
    } else {
      return true;
    }
  };
  handleBlur = (field) => (evt) => {
    this.setState({
      touched: { ...this.state.touched, [field]: true },
    });
  };

  closePopUpModal = () => {
    this.setState({
      errorState: "",
      showPopupModal: "",
      successState: "",
      emailID: "",
      fullName: "",
      fileName: null,
      yearsOfExp: "",
      techStack: "",
      noticePeriod: "",
      intrestPos: "",
      emailIDError: "",
      isSubmitted: false,
      touched: {
        fileName: false,
        emailID: false,
        fullName: false,
        yearsOfExp: false,
        techStack: false,
        noticePeriod: false,
        intrestPos: false,
      },
    });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    this.setState({ isSubmitted: true });
    let resumedata = this.state.fileData;
    let reader = new FileReader();
    reader.readAsDataURL(this.state.fileData);
    reader.onload = (e) => {
      var formdata = new FormData();
      formdata.append("resume", resumedata, this.state.fileName);
      formdata.append(
        "data",
        `{"contents": {"name": "Codeparva Careers","senderEmail":"${senderMailID}","bcc":"${bcc}","receiverEmail":"${receiverMailID}","candiateEmail":"${
          this.state.emailID
        }","candidateName": "${this.state.fullName}","subject": "${
          this.state.fullName
        } (${
          window.location.pathname === "/career"
            ? "Not applied for any particular position - General"
            : this.state.jobPosition
        })","message": "Candidate Details","customTemplate":"<html> <body> <div> <p>You got a new message from <strong>Codeparva Careers</strong></p> <p>Sender's Email: <strong>${senderMailID}</strong></p> </div> <div style='border-left: 5px solid #DDDDDD; margin:20px; padding: 20px;'><strong>Details Of The Candidate</strong></div><div style='width:800px;height:250px;border:2px solid grey;margin-left: 20px; padding-bottom: 70px;'><div style='margin-left: 20px'><br><p style='font-family: system-ui; font-size:13px'><b>Name of Candidate : </b>${
          this.state.fullName
        }</p><p style='font-family: system-ui; font-size:13px'><b>Years of Experience : </b>${
          this.state.yearsOfExp
        }</p><p style='font-family: system-ui; font-size:13px'><b>Job Position Applied For : </b> ${
          window.location.pathname === "/career"
            ? "Not applied for any particular position - General"
            : this.state.jobPosition
        }</p><p style='font-family: system-ui; font-size:13px'><b>Skills (currently working in/have hands-on exp in) : </b>${
          this.state.techStack
        }</p><p style='font-family: system-ui; font-size:13px'><b>Notice Period / Last working day (if serving notice) : </b>${
          this.state.noticePeriod
        }</p><p style='font-family: system-ui; font-size:13px'><b>Source: </b>${
          this.state.source
        }</p><p style='font-family: system-ui; font-size:13px'><b>Reason for Looking out : </b>${
          this.state.intrestPos
        }</p><p style='font-family: system-ui; font-size:13px'><b>Alternate Contact info (apart from what is mentioned in the resume, if any) : </b>${
          this.state.altContact
        }</p></div></div></body></html>"}}`
      );
      var requestOptions = {
        method: methodType,
        body: formdata,
        redirect: redirect,
      };

      (async () => {
        try {
          const response = await fetch(jobApplyUrl, requestOptions);
          const data = await response.json();

          if (data.statusCode === 3) {
            this.setState({
              successState:
                "Successfully Applied, Thankyou for taking your time to apply, Our HR Team will get back to you soon!",
            });

            this.setState({ isSubmitted: true });
          } else {
            alert("Error" + data.message);
            window.location.reload();
          }
          this.myFormRef.reset();
        } catch (err) {
          this.setState({
            errorState: "Error - Server Under Maintainance",
          });
        }
      })();
    };
  };
  render() {
    const errors = validate(
      this.state.emailID,
      this.state.fullName,
      this.state.fileName,
      this.state.yearsOfExp,
      this.state.techStack,
      this.state.noticePeriod,
      this.state.intrestPos
    );
    const shouldMarkError = (field) => {
      const hasError = errors[field];
      const shouldShow = this.state.touched[field];
      return hasError ? shouldShow : false;
    };
    return (
      <Modal
        size="xl"
        show={this.state.showPopupModal}
        onHide={this.props.handleJobApplyModal}
        dialogClassName="modal-90w"
        backdrop="static"
        keyboard={false}
      >
        <Form
          id="myForm"
          ref={(el) => {
            this.formControl(el);
          }}
          className="container"
          autoComplete="off"
          onSubmit={this.onFormSubmit}
        >
          <Modal.Header
            closeButton={true}
            onClick={(e) => {
              this.closePopUpModal(e);
            }}
          >
            <Modal.Title as="h2" style={{ paddingLeft: 20 }}>
              {this.state.errorState === "" ? "" : ""}
            </Modal.Title>
          </Modal.Header>
          {this.state.successState === "" ? (
            <>
              {this.state.errorState === "" ? (
                <>
                  <Modal.Body>
                    <Modal.Title as="h4" className="modal-subtitle text-left">
                      <h1> Apply Here </h1>
                      Please make sure that you have mentioned your contact
                      details properly in your resume. Our HR member will get in
                      touch with you
                    </Modal.Title>
                    <Container style={{ padding: 0 }}>
                      <Row>
                        <Col md={12}>
                          <label className="modal-label" htmlFor="full-resume">
                            Upload your resume (PDF Only)
                          </label>
                          <span className="required">*</span>
                          <label
                            className="modal-input"
                            style={{ width: "100%", cursor: "pointer" }}
                          >
                            {this.state.fileName
                              ? this.state.fileName
                              : "Choose File"}
                            <FormControl
                              accept="application/pdf"
                              required
                              id="full-resume"
                              type="file"
                              className={
                                shouldMarkError("fileName")
                                  ? "error"
                                  : "modal-input"
                              }
                              aria-describedby="basic-addon3"
                              onBlur={this.handleBlur("fileName")}
                              onChange={(e) => this.getFileName(e)}
                              style={{ display: "none" }}
                            />
                          </label>
                          {this.state.fileExtension !== "pdf" &&
                          this.state.fileExtension !== "docx" &&
                          this.state.fileExtension.length !== 0 ? (
                            <span className="errorMessage">
                              Please attach a valid resume
                            </span>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <Row>
                        <Col md={12}>
                          <label
                            className="modal-label"
                            htmlFor="candidate-name"
                          >
                            Name of Candidate
                          </label>
                          <span className="required">*</span>
                          <FormControl
                            required
                            id="candidate-name"
                            className={
                              shouldMarkError("fullName")
                                ? "error"
                                : "modal-input"
                            }
                            maxLength={150}
                            aria-describedby="basic-addon3"
                            value={this.state.fullName}
                            onBlur={this.handleBlur("fullName")}
                            onChange={(e) => {
                              this.nameValidation(e);
                            }}
                          />
                          {errors.fullName && this.state.touched.fullName && (
                            <span className="errorMessage">
                              Please enter the name of the candidate
                            </span>
                          )}
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <Row>
                        <Col md={12}>
                          <label
                            className="modal-label"
                            htmlFor="candidate-email"
                          >
                            Candidate Email
                          </label>
                          <span className="required">*</span>
                          <FormControl
                            id="candidate-email"
                            type="email"
                            className={
                              this.state.emailIDError !== "Success" &&
                              this.state.emailIDError !== ""
                                ? "error"
                                : "modal-input"
                            }
                            maxLength={320}
                            aria-describedby="basic-addon3"
                            onBlur={this.handleBlur("emailID")}
                            onChange={(e) => {
                              this.validateEmailId(e);
                            }}
                          />
                          {this.state.touched.emailID &&
                            this.state.emailIDError !== "Success" && (
                              <span className="errorMessage">
                                {this.state.emailIDError}
                              </span>
                            )}
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <Row>
                        <Col md={12}>
                          <label
                            className="modal-label"
                            htmlFor="candidate-experience"
                          >
                            Years of Experience
                          </label>
                          <span className="required">*</span>
                          <FormControl
                            as="select"
                            id="candidate-experience"
                            className={
                              shouldMarkError("yearsOfExp")
                                ? "error"
                                : "modal-input"
                            }
                            aria-describedby="basic-addon3"
                            value={this.state.yearsOfExp}
                            onBlur={this.handleBlur("yearsOfExp")}
                            onChange={(e) =>
                              this.setState({ yearsOfExp: e.target.value })
                            }
                          >
                            <option>--Select--</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="5">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </FormControl>
                          {errors.yearsOfExp &&
                            this.state.touched.yearsOfExp && (
                              <span className="errorMessage">
                                Please select the years of experience
                              </span>
                            )}
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <Row>
                        <Col md={12}>
                          <label className="modal-label" htmlFor="tech-stack">
                            <b className="tech-stack">Skills</b> (currently
                            working in/have hands-on exp in) Seperate them by
                            commas.
                          </label>
                          <span className="required">*</span>
                          <FormControl
                            id="tech-stack"
                            className={
                              shouldMarkError("techStack")
                                ? "error"
                                : "modal-input"
                            }
                            maxLength={100}
                            aria-describedby="basic-addon3"
                            value={this.state.techStack}
                            onBlur={this.handleBlur("techStack")}
                            onChange={(e) =>
                              this.setState({ techStack: e.target.value })
                            }
                          />
                          {errors.techStack && this.state.touched.techStack && (
                            <span className="errorMessage">
                              Please enter the relevant skills known to you
                            </span>
                          )}
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <Row>
                        <Col md={12}>
                          <label
                            className="modal-label"
                            htmlFor="notice-period"
                          >
                            Notice Period / Last working day (if serving notice)
                          </label>
                          <span className="required">*</span>
                          <FormControl
                            id="notice-period"
                            className={
                              shouldMarkError("noticePeriod")
                                ? "error"
                                : "modal-input"
                            }
                            maxLength={20}
                            aria-describedby="basic-addon3"
                            value={this.state.noticePeriod}
                            onBlur={this.handleBlur("noticePeriod")}
                            onChange={(e) =>
                              this.setState({ noticePeriod: e.target.value })
                            }
                          />
                          {errors.noticePeriod &&
                            this.state.touched.noticePeriod && (
                              <span className="errorMessage">
                                Please enter the Notice Period
                              </span>
                            )}
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <Row>
                        <Col md={6}>
                          <label
                            className="modal-label"
                            htmlFor="leave-a-message"
                          >
                            Where did you hear about us
                          </label>
                          <br />
                          <input
                            type="radio"
                            id="source"
                            name="source"
                            value="Google"
                            className="radio-options checkbox"
                            onChange={(e) => {
                              this.validateSource(e);
                            }}
                          />
                          <label> Google </label>
                          <br />
                          <input
                            type="radio"
                            id="source"
                            name="source"
                            value="LinkedIn"
                            className="radio-options checkbox"
                            onChange={(e) => {
                              this.validateSource(e);
                            }}
                          />
                          <label> LinkedIn </label>
                          <br />
                          <FormControl
                            type="text"
                            className="modal-input"
                            id="source"
                            name="source"
                            placeholder="Others"
                            value={this.state.source}
                            onChange={(e) => {
                              this.validateSource(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <Row>
                        <Col md={12}>
                          <label className="modal-label" htmlFor="alt-contact">
                            Alternate Contact Number (apart from what is
                            mentioned in the resume, if any)
                          </label>
                          <FormControl
                            id="alt-contact"
                            className="modal-input"
                            maxLength={10}
                            aria-describedby="basic-addon3"
                            value={this.state.altContact}
                            onChange={(e) => {
                              this.altContactValidation(e);
                            }}
                          />
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <Row>
                        <Col md={12}>
                          <label
                            className="modal-label"
                            htmlFor="leave-a-message"
                          >
                            Briefly describe why you are interested in this
                            position....
                          </label>
                          <span className="required">*</span>
                          <FormControl
                            id="leave-a-message"
                            className={
                              shouldMarkError("intrestPos")
                                ? "error"
                                : "modal-textarea"
                            }
                            as="textarea"
                            rows="8"
                            maxLength={100}
                            aria-describedby="basic-addon3"
                            value={this.state.intrestPos}
                            onBlur={this.handleBlur("intrestPos")}
                            onChange={(e) =>
                              this.setState({ intrestPos: e.target.value })
                            }
                          />
                          {errors.intrestPos &&
                            this.state.touched.intrestPos && (
                              <span className="errorMessageBox">
                                Please fill out the reason you are interested in
                                this position
                              </span>
                            )}
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <div className="PrivacyPolicy">
                        {this.state.fullName.length !== 0 &&
                        this.state.fileName !== null &&
                        this.state.yearsOfExp.length !== 0 &&
                        this.state.techStack.length !== 0 &&
                        this.state.noticePeriod.length !== 0 &&
                        this.state.intrestPos.length !== 0 ? (
                          <>
                            <input
                              type="checkbox"
                              name="privacyPolicy"
                              onChange={(e) => {
                                this.enableSubmitButton(e);
                              }}
                            />
                            <label style={{ paddingLeft: "5px" }}>
                              I have read and agree to the
                              <Link
                                type="button"
                                to="/privacy-policy"
                                target="_blank"
                                style={{
                                  marginLeft: "8px",
                                  textAlign: "center",
                                  borderRadius: "30px",
                                  background: "#000",
                                  backgroundColor: "#fff",
                                  color: "#000",
                                }}
                                onClick={() => {
                                  this.handlePrivacyPolicyState();
                                }}
                              >
                                Privacy Policy
                              </Link>
                            </label>
                          </>
                        ) : (
                          <>
                            <input
                              type="checkbox"
                              name="privacyPolicy"
                              disabled={true}
                              onChange={(e) => {
                                this.enableSubmitButton(e);
                              }}
                            />
                            <label
                              style={{ paddingLeft: "5px", color: "#cecccc" }}
                            >
                              I have read and agree to the
                              <Link
                                type="button"
                                to="/privacy-policy"
                                target="_blank"
                                style={{
                                  pointerEvents: "none",
                                  marginLeft: "8px",
                                  textAlign: "center",
                                  borderRadius: "30px",
                                  background: "#000",
                                  backgroundColor: "#fff",
                                  color: "rgb(206, 204, 204)",
                                }}
                              >
                                Privacy Policy
                              </Link>
                            </label>
                          </>
                        )}
                      </div>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer style={{ justifyContent: "center" }}>
                    <Button
                      form="myForm"
                      style={{
                        borderRadius: "30px",
                        border: "1px solid gray",
                        background: "#fff",
                        width: "14rem",
                        height: "3rem",
                        backgroundColor: "#27323d",
                        color: "white",
                      }}
                      disabled={this.state.buttonState}
                      onClick={(e) => {
                        this.onFormSubmit(e);
                      }}
                    >
                      {this.state.isSubmitted ? <Spinners /> : "Submit"}
                    </Button>
                  </Modal.Footer>
                </>
              ) : (
                <Alert variant="danger">
                  <Alert.Heading className="alertHeading">
                    Oh snap! You got an error!
                  </Alert.Heading>
                  <p className="alertPara">{this.state.errorState}</p>
                </Alert>
              )}{" "}
            </>
          ) : (
            <Alert show={this.state.alertShow} variant="success">
              <Alert className="alertHeading">Hurray!!!</Alert>
              <p className="alertPara">{this.state.successState}</p>
              <hr />
            </Alert>
          )}
        </Form>
      </Modal>
    );
  }
}

export default withRouter(JobApplyPopupModal);
