import React from "react";
import { CareerSection } from "../careerSection/careerSection";
import { CareerHeading } from "../careerHeading/careerHeading";
import { mobileScreen } from "../../constants/screen";

export const Career = ({
  careerPage_description,
  PageHeadingH1,
  main_section,
  headImg,
  setisJobApplyModalOpen,
}) => {
  const width = window.screen.width;
  const showOpenPositionSection = JSON.parse(process.env.REACT_APP_HIRING).status || false
  return (
    <div className={`Career  ${width <= mobileScreen && "text-center"}`}>
      <CareerHeading
        careerPara={careerPage_description}
        PageHeadingH1={PageHeadingH1}
        headImg={headImg}
        showOpenPositionSection={showOpenPositionSection}
      />
      <div>
        {showOpenPositionSection && main_section.map((item, index) => (
          <CareerSection key={index} item={item} />
        ))}
        {/* <div className="container CareerApplySection d-flex flex-column">
          <Heading className="py-3 mb-0 col-md-8 col-12 px-0" RenderAs="h4">
            Don’t see the right position for you ?
          </Heading>
          <div className="is-body-text-3 careerPageTitle col-md-8 col-12 px-0">
            If you are extremely talented, take ownership for your work and are
            a team player we'd greatly appreciate if you'd send us your resume,
            even if you don't see an open job post that's right for you.
          </div>
          <div className="pb-4">
            <CTA
              className="is-cp-primary-small-button"
              icon={""}
              onClick={setisJobApplyModalOpen}
            >
              Send Your Resume{" "}
            </CTA>
          </div>
        </div> */}
      </div>
    </div>
  );
};
