import React, { useEffect, useState } from "react";
import { Layout } from "../../Components/layout/Layout";
import { Career } from "../../Components/Career/Career";
import {
  careerPage_description,
  PageHeadingH1,
  main_section,
  headImg,
} from "./config";
import JobApplyPopupModal from "../../Components/jobApplyModal/jobApplyModal";
export const CareerPage = () => {
  const [isJobApplyModalOpen, setisJobApplyModalOpen] = useState(false);

  useEffect(() => {
    if (window && window.scrollTo) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  });

  const handleJobApplyModal = () => {
    // this.setState({ isJobApplyModalOpen: !this.state.isJobApplyModalOpen });
    setisJobApplyModalOpen(!isJobApplyModalOpen);
  };

  return (
    <Layout>
      <JobApplyPopupModal
        show={isJobApplyModalOpen}
        handleJobApplyModal={() => handleJobApplyModal}
      />
      <Career
        careerPage_description={careerPage_description}
        PageHeadingH1={PageHeadingH1}
        main_section={main_section}
        headImg={headImg}
        setisJobApplyModalOpen={setisJobApplyModalOpen}
      />
    </Layout>
  );
};
