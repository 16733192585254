import React from "react";
import { Row, Col } from "react-bootstrap";
import * as img from "../../constants/images";
import { CTA } from "../../Helpers/Button/Button";

export const WorkWithUsHeaderSection = (props) => {
  if (props.isMobile) {
    return (
      <div className="d-flex headerSection container align-items-center ">
        <div className="col-12 p-0 h-100 d-flex justify-content-center flex-column">
          {/* <div className="w-100 justify-content-center d-flex">
                        <p style={{ color: "#212529", lineHeight: "1" }} className="headerTitle" >Fun set of people to work with</p>
                    </div> */}
          <div
            style={{ padding: "0rem 0rem", textAlign: "center" }}
            className="w-100 justify-content-center d-flex"
          >
            <Row>
              <Col md={6} sm={6} className="subBox">
                <img
                  style={{ zIndex: 10 }}
                  className="bannerSubLogo"
                  src={img.BRAINSTORM}
                  alt="img"
                />
                <label className="bannerSubTitle">
                  <p className="is-cp-body-text-1">Work With a fun team</p>
                </label>
              </Col>
              <Col md={6} sm={6} className="subBox">
                <img
                  style={{ zIndex: 10 }}
                  className="bannerSubLogo"
                  src={img.REFUND}
                  alt="img"
                />
                <label className="bannerSubTitle">
                  {/* <h5 className="mb-0">
                    
                  </h5> */}
                  <p className="is-cp-body-text-1">
                    Reimbursements for food and fuel (Internet during
                    Covid-induced WFH)
                  </p>
                </label>
              </Col>
            </Row>
          </div>
          <div
            style={{ padding: "0rem 0rem", textAlign: "center" }}
            className="w-100 justify-content-center d-flex"
          >
            <Row>
              <Col md={6} sm={6} className="subBox">
                <img
                  style={{ zIndex: 10 }}
                  className="bannerSubLogo"
                  src={img.TEAM}
                  alt="img"
                />
                <label className="bannerSubTitle">
                  <p className="is-cp-body-text-1">
                    Well paid team = Happy team = Productive team
                  </p>
                </label>
              </Col>
              <Col md={6} sm={6} className="subBox">
                <img
                  style={{ zIndex: 10 }}
                  className="bannerSubLogo"
                  src={img.GROUP}
                  alt="img"
                />
                <label className="bannerSubTitle">
                  <p className="is-cp-body-text-1">
                    Anti-discrimination policies
                  </p>
                </label>
              </Col>
            </Row>
          </div>
          <div className="w-100 justify-content-center d-flex py-2">
            {props.isHome ? (
              <CTA
                className="is-cp-secondary-button"
                href="jobs/bengaluru/open-positions"
                // icon={{
                //   alt: "location",
                //   icon: img.LOCATION,
                // }}
                icon={""}
              >
                {" "}
                Work With Us
              </CTA>
            ) : (
              <CTA
                className="is-cp-secondary-button"
                href="jobs/bengaluru/open-positions"
                // icon={{
                //   alt: "location",
                //   icon: img.LOCATION,
                // }}
                icon={""}
              >
                {" "}
                View Open Positions
              </CTA>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-flex headerSection container align-items-center">
        <div className="col-12 p-0 h-100 d-flex justify-content-center flex-column">
          {/* <div>
                        <p style={{ color: "#212529", lineHeight: "1.5" }} className="headerTitle" >Fun set of people to work with</p>
                    </div> */}
          <div
            style={{ padding: "3rem 0rem", marginTop: -100, marginBottom: 20 }}
          >
            <Row>
              <Col sm={6} md={3}>
                <img
                  style={{ zIndex: 10 }}
                  className="bannerSubLogo"
                  src={img.BRAINSTORM}
                  alt="img"
                />
                <label className="bannerSubTitle">
                  <p className="is-cp-body-text-1">Work With a fun team</p>
                </label>
              </Col>
              <Col sm={6} md={3}>
                <img
                  style={{ zIndex: 10 }}
                  className="bannerSubLogo"
                  src={img.REFUND}
                  alt="img"
                />
                <label className="bannerSubTitle">
                  <p className="is-cp-body-text-1">
                    Reimbursements for food and fuel (Internet during
                    Covid-induced WFH)
                  </p>
                </label>
              </Col>
              <Col sm={6} md={3}>
                <img
                  style={{ zIndex: 10 }}
                  className="bannerSubLogo"
                  src={img.TEAM}
                  alt="img"
                />
                <label className="bannerSubTitle">
                  <p className="is-cp-body-text-1">
                    Well paid team = Happy team = Productive team
                  </p>
                </label>
              </Col>
              <Col sm={6} md={3}>
                <img
                  style={{ zIndex: 10 }}
                  className="bannerSubLogo"
                  src={img.GROUP}
                  alt="img"
                />
                <label className="bannerSubTitle">
                  <p className="is-cp-body-text-1">
                    Anti-discrimination policies
                  </p>
                </label>
              </Col>
            </Row>
          </div>
          <div
            className="w-100 justify-content-center d-flex py-2"
            style={{ marginBottom: -60 }}
          >
            {props.isHome ? (
              <CTA
                className="is-cp-secondary-button"
                href="career"
                // icon={{
                //   alt: "location",
                //   icon: img.LOCATION,
                // }}
                icon={""}
              >
                {" "}
                Work With Us
              </CTA>
            ) : (
              <CTA
                className="is-cp-secondary-button"
                href="jobs/bengaluru/open-positions"
                // icon={{
                //   alt: "location",
                //   icon: img.LOCATION,
                // }}
                icon={""}
              >
                {" "}
                View Open Positions
              </CTA>
            )}
          </div>
        </div>
      </div>
    );
  }
};
