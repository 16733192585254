import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Multilanguges Components
import { IntlProvider } from "react-intl";

//componets
import { HomePage } from "../Pages/HomePage/homePage";
import { WorkWithUsPage } from "../Pages/WorkWithUsPage/workWithUsPage";
import { OpenPositionPage } from "../Pages/OpenPositionPage/openPositionPage";
import { PrivacyPolicyPage } from "../Pages/PrivacyPolicyPage/privacyPolicyPage";
import { NotFound } from "../Pages/NotFoundPage/notFoundPage";
import { CareerPage } from "../Pages/CareerPage/careerPage";
import { JobDescriptionPage } from "../Pages/JobDescriptionPage/jobDescriptionPage";
import { main_section } from "../Pages/CareerPage/config";

function Index(props) {
  const { sub_section } = main_section[0];

  return (
    <IntlProvider>
      <Router>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/work-with-us" component={WorkWithUsPage} />
          <Route path="/career" component={CareerPage} />
          <Route
            exact
            path="/jobs/bengaluru/open-positions"
            component={OpenPositionPage}
          ></Route>

          <Route path="/privacy-policy" component={PrivacyPolicyPage} />
          {sub_section.map((item) => {
            return item.current_opening.map((opening, id) => {
              return (
                <Route
                  path={`/jobs/bengaluru/open-positions/${opening.slug}`}
                  component={JobDescriptionPage}
                  key={id}
                />
              );
            });
          })}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </IntlProvider>
  );
}

export default Index;
